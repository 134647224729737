
.contact-us{

    .message-form-result{
        margin-bottom: 30px;
    }

    .alternative-contacts{
        align-items: center;
        padding: 0;
        max-resolution: 0;

        .whatsapp-icon{
            color: green;
            margin-right: 3px;
        }
    }

    .btn-actions{
        display: flex;
        flex-direction: row-reverse;
        
        .send{
            display: flex;
            align-items: center;
            height: 35px !important;
            font-size: 1.2em;
            
            &:disabled{
                background-color: #ccc !important;
                border-color: #ccc !important;
                color: #8a8a8a;
            }
        }
    }

    @media only screen and (max-width: 1280px) {
        .title{
            &::before{
                margin-top: 82px !important;
            }
        }
    }
    
    h3{
        small{
            display:block;
        }
    }
}