.cookies{
    z-index: 1000;
    padding: 0 10px;
    position: fixed;
    bottom: 0;
    width: 100vw;
    background: white;
    box-shadow: 0 0 55px 10px rgba(0, 0, 0, 0.1);
    border-top: solid 1px #cfcfcf;

    .actions{
        button{
            margin-left: 5px;
        }
        padding-right: 20px;
        display: flex;
        justify-content: end;
    }
    
}