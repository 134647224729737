
.header{

    /*Mobile version*/
    @media only screen and (max-width: 1000px) {
        padding: 10px;
    }

        
    padding: 20px 150px 0 150px;
    background-color: #f5f7fa;
    text-align:center;
    display: flex;
    min-height: 50vh;
    box-shadow: 0 0 30px 10px rgba(16, 22, 36, 0.1);
    padding-bottom: 50px;

    h1{
        font-size: 3.5rem;
        font-weight: 900;
        font-family: "Nunito Sans";
        margin-bottom: 20px;
        margin-top: 20px;
    }

    

}