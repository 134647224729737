.footer{
    background-color:#f5f7fa;
    margin-top: 100px;
    font-family: "Nunito Sans";
    border: solid 1px #e6e9ef;
    border-width: 1px 0 0 0;
    box-shadow: 0 0 30px 10px rgba(16, 22, 36, 0.1);
    padding-bottom: 100px;

    .logo{
        max-width: 100px;
    }

    .qr-contact{
        max-width: 100%;
        max-height: 100%;
    }

    small{
        display:block;
        margin-top: 5px;
    }
}