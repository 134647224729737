.web-development{
    padding: 40px 0 40px 0;
    @media only screen and (max-width: 1000px) {
        .row:first-child{
            display:flex;
            flex-direction: column-reverse;
        }
    }

    &:hover{
        .section-image{
            transform: rotateY(25deg) rotateX(-15deg);
            transition: transform 2s;  
        }
    }

    .section-image{
        order: 0;
        background-image: url(/images/image1.png);
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        min-height: 400px;
        transition: all 1.5s;
    }

    .tab-50px{
        padding-left: 50px;
    }

    .text-box{
        font-family: "Nunito Sans";
        color: #5c6689;
        font-size: 16px;
        font-weight: 400;
    }
    
}