.our-workflow{

    margin-top: 0 !important;

    @media only screen and (max-width: 1000px) {
        .row:first-child{
            display:flex;
            flex-direction: column-reverse;
        }
    }

    .tab-50px{
        padding-left: 50px;
    }

    .text-box{
        font-family: "Nunito Sans";
        color: #5c6689;
        font-size: 16px;
        font-weight: 400;
    }


    .work-flow-main-info{
        h3, h1{
            font-family: "Nunito Sans";
        }

        h1{
            font-weight: 700;
            margin-bottom: 0;
        }
        
        h3{
            margin-top: 0;
        }

        p{
            button{
                display: block;
                margin: 30px auto 0 auto;
            }
        }
    }

    .extra-info{
        display: flex;
        align-items: center;
    }
    
}