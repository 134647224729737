@import './styles/colors.scss';

@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap');

html {
  scroll-behavior: smooth;
}

@keyframes anchor{
  0%{
    width: 100px
  }

  50%{
    width: 50px;
  }

  100%{
    opacity: 100px;
  }
}

#root{
  overflow: hidden;
}

.app {

  .section{
    margin-top:60px;
    padding: 40px 20px;

    @media only screen and (max-width: 1280px) {
      margin-top:10px;
      padding: 10px 20px;
    }

  }

  .title{
    font-family: "Nunito Sans";
    font-weight: 900;
    font-size: 34px;
    margin-bottom: 4rem;
    position: relative;
    color: #161635;

    &::before{
      position: absolute;
      margin-top: 50px;
      content: "";
      width: 100px;
      background-color:$pink-color;
      height: 12px;
      border-radius: 3px;
      animation: anchor 6s cubic-bezier(0.53, 0.53, 0.53, 0.53);
      animation-iteration-count: infinite;
    }
  }

  .sub-title{
    font-family: "Nunito Sans";
    font-weight: 700;
    margin-bottom: 1rem;
    color: rgb(22, 22,53);
    height: 35px;
    font-size: 23px;
    font-style: italic;
    padding-top: 0.71429rem;

    .fa-icon{
      font-size: 35px;
      font-weight: lighter;
      margin-right: 10px;
      color: #5c6689;
    }
  }

  .get-started{
    height: 2em;
    font-size: 2em;
    padding: 0 30px;
    margin-top: 20px;
}

}

.ant-btn-primary{
  font-family: "Nunito Sans";
  background-color: #ff0476 !important;
  border-color: #ff0476 !important;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus{
  background-color: #ff2689 !important;
  border-color: #ff2689 !important;
}

.btn-call-to-action{
  background-color: rgb(51, 153, 51) !important;
}

.triangle-background-image{
  position:absolute;
  width: 100%;
  height:50vh ;
  @media (max-width: 545px) {
    height: 50vh !important;
  }
  -webkit-clip-path: polygon(67% 0%, 110% 0%, 30% 100%, 28% 100%); 
  clip-path: polygon(67% 0%, 110% 0%, 30% 100%, 28% 100%);
  background-image: url(/images/abstractbg.jpg);
  background-blend-mode: lighten;
  background-color: rgba(255,255,255,0.55);
  background-position: center;
  background-size: contain;
  top:0;
  left:0;
}


.contact-world-points{
  width: 100%;
  height: 50%;
  -webkit-clip-path: polygon(-12% 0%, 0% 70%, 123% 100%);
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%,0% 100%);
  background-image: url(https://upload.wikimedia.org/wikipedia/commons/thumb/6/67/Pacific_centered_world_map_with_points.svg/512px-Pacific_centered_world_map_with_points.svg.png);
  background-blend-mode: lighten;
  background-color: rgba(255, 38, 137, 0.15);
  background-position: right;
  background-size: contain;
  position: absolute;
  left: 0;
  margin-top: -100px;
  background-repeat: no-repeat;
}
