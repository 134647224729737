.jobs{
    padding: 80px 0 40px 0;
    font-family: "Nunito Sans";

    .product-info{
        max-width: 100%;
        border: solid 1px;
        border-color:#f0f0f0;
        padding: 10px;
        border-radius: 7px;
        display: flex;
        box-shadow: 0 0 5px 1px rgba(16, 22, 36, 0.1);
        .shield{

            @media only screen and (max-width: 1000px) {
                display: none;
                visibility: hidden;
            }

            width: 400px;
            height: 200px;
            background-image: url(../../images/v.png);
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;

            .triangle-bottomright {
                width: 0;
                height: 0;
                border-bottom: 270px solid white;
                border-left: 200px solid transparent;
                position: inherit;
                float: right;
                filter:alpha(opacity=90);
                -moz-opacity:0.9;
                -khtml-opacity: 0.9;
                opacity: 0.9;
              }
        }

        .body{
            padding-left: 20px;
            padding-right: 25px;
            max-width: 100%;
            .call-to-action{
                text-align: right;

                @media (max-width: 545px) {
                    height: auto !important;
                }
                
            }

            .actions{
                display: flex;
                align-items: flex-end;
                flex-direction: row-reverse;
                padding-bottom: 10px;
                height: 100px;

                @media (max-width: 545px) {
                    width: 100%;
                    display: inline;
                }

                button{
                    margin-left: 5px;
                    display: flex;
                    align-items: center;
                    
                    @media (max-width: 545px) {
                        width: 100%;
                        margin-bottom: 10px;
                        justify-content: center;
                    }

                    height: 40px !important;

                    &.download-brochure{
                        color: white;

                        &:disabled{
                            background-color: #ccc !important;
                            border-color: #ccc !important;
                            color: #8a8a8a;
                        }
                    }

                    &.contact{
                        height: 55px !important;
                        font-size: 1.5em;
                    }
                }
            }
            
        }

    }
    
    .job-list{
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .job{
            margin: 10px 0 0 2%;
            display: inline-block;
            flex-grow: 1;
            width: calc(100% * (1/4) - 10px - 1px);
        }
    }
}