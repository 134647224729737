// Velocities
$velocityFirst: 85s;
$velocitySecond: 75s;
$velocityThird: 60s;
$velocityFourth: 50s;

// Way
$dirIzq:360deg;
$dirDer:-360deg;

// Colors
$colorBlueLight:#98d0f2;
$colorBlueDark:#0099f8;
$mainBackGround: white;

$background-github: rgb(28, 117, 187);
$background-linux: rgb(243, 191, 0);
$background-windows: #0cb3ee;
$background-iis: #0cb3ee;
$background-php: #3765af;
$background-dotnet: #68217a;
$background-react: #3765af;
$background-apache: #d9d9d9;
$background-nginx: #43a047;
$background-angular: #a60025;
$background-jenkins: #388cba;


// Sizes
$space-height: 300px;
$space-width: 100%;

// Others
$orbitLineStyle: solid;
$orbitBorderWidthHover: 1.5px;


@keyframes orbitToIzq{
	from{
		transform: rotate($dirIzq); 
	}
}

@keyframes orbitToDer{
	from{
		transform: rotate($dirDer); 
	}
}

@keyframes planetToIzq{
	from{
		transform: rotate($dirIzq);
	}
}

@keyframes planetToDer{
	from{
		transform: rotate($dirDer);
	}
}

.space{
	background-color:$mainBackGround;
	width: $space-width;
	height: $space-height;
	display:flex;
	align-items:center;
    justify-content: center;
    margin-top: 150px;
    
    @media only screen and (max-width: 1000px) {
        margin-bottom: 50px;
		margin-top: 0;
		min-height: 450px;
    }
	
	.orbit{
		position:absolute;
		
		border-radius: 100%;
		
		.planet{
			position:absolute;
			width: 10px;
			height:10px;
            border-radius: 100%;
            
            
            &.no-icon{
                width: 20px;
                height: 20px;
            }
			
			svg{
				margin-left:-12.5px;
				margin-top:-20px;
				width: 50px;
				height: 50px;
				filter: drop-shadow(rgba(0, 0, 0, 0.5) 5px 5px 10px);

				path{
					fill: $colorBlueDark;
					stroke:white;
					stroke-width:2px;

					&.github{
						fill: $background-github !important;
					}

					&.linux{
						fill: $background-linux !important;
					}

					&.windows{
						fill: $background-windows !important;
					}

					&.php{
						fill: $background-php !important;
					}

					&.dotnet{
						fill: $background-dotnet !important;
					}

					&.react{
						fill: $background-react !important;
					}

					&.apache{
						fill: $background-apache !important;
					}

					&.iis{
						fill: $background-iis !important;
					}

					&.nginx{
						fill: $background-nginx !important;
					}

					&.angular{
						fill: $background-angular !important;
					}

					&.jenkins{
						fill: $background-jenkins !important;
					}
				}
				
				&:hover{
					cursor:pointer;
				}
			}
		}
		
		&#first{
			background-color:transparent;
			z-index:100;
			border: $orbitLineStyle 1.5px $colorBlueLight;
			width: 100px;
			height: 100px;
			animation: orbitToIzq $velocityFirst cubic-bezier(0.53,0.53,0.53,0.53) infinite;
			
			&:hover{
				border-width:$orbitBorderWidthHover;
				animation-play-state:paused !important;
				background-color:rgba(0,0,255,.1);
				
				.text-shape{
					visibility:visible;
				}
				
				.text-shape,
				.planet{
					animation-play-state:paused !important;
				}
			}
			
			.planet{
                animation: planetToDer $velocityFirst cubic-bezier(0.53,0.53,0.53,0.53) infinite;

                
				&.one{
					background-color:orange;
					margin: 50px 0 0 -5px;
				}

				&.two{
					background-color:blue;
					margin: 50px 0 0 85px;
				}
				
			}
		}
		
		&#second{
			background-color:transparent;
			z-index:99;
			border: $orbitLineStyle 1.5px $colorBlueDark;
			width: 200px;
			height: 200px;
			animation: orbitToDer $velocitySecond cubic-bezier(0.53,0.53,0.53,0.53) infinite;
			
			&:hover{
				border-width:$orbitBorderWidthHover;
				animation-play-state:paused !important;
				background-color:rgba(0,0,255,.1);
				
				.planet{
					animation-play-state:paused !important;
				}
			}
			
			.planet{
				animation: planetToIzq $velocitySecond cubic-bezier(0.53,0.53,0.53,0.53) infinite;

				
				
				&.one{
					background-color:orange;
					margin: 0 0 0 90px;
				}

				&.two{
					background-color:blue;
					margin: 145px 0 0 5px;
				}

				&.three{
					background-color:yellow;
					margin: 145px 0 0 175px;
				}

				
			}
		}
		
		&#third{
			background-color:transparent;
			z-index:98;
			border: $orbitLineStyle 1.5px $colorBlueLight;
			width: 300px;
			height: 300px;
			animation: orbitToIzq $velocityThird cubic-bezier(0.53,0.53,0.53,0.53) infinite;
			
			&:hover{
				border-width:$orbitBorderWidthHover;
				animation-play-state:paused !important;
				background-color:rgba(0,0,255,.1);
				
				.planet{
					animation-play-state:paused !important;
				}
			}
			
			.planet{
				animation: planetToDer $velocityThird cubic-bezier(0.53,0.53,0.53,0.53) infinite;

				
				
				&.one{
					background-color:orange;
					margin: 65px 0 0 15px;
				}

				&.two{
					background-color:blue;
					margin: 65px 0 0 265px;
				}

				&.three{
					background-color:yellow;
					margin: 265px 0 0 235px;
				}

				&.four{
					background-color:pink;
					margin: 265px 0 0 45px;
				}
			}
        }
        
        &#fourth{
			background-color:transparent;
			z-index:97;
			border: $orbitLineStyle 1.5px $colorBlueDark;
			width: 400px;
			height: 400px;
			animation: orbitToDer $velocityFourth cubic-bezier(0.53,0.53,0.53,0.53) infinite;
			
			&:hover{
				border-width:$orbitBorderWidthHover;
				animation-play-state:paused !important;
				background-color:rgba(0,0,255,.1);
				
				.planet{
					animation-play-state:paused !important;
				}
			}
			
			.planet{
				animation: planetToIzq $velocityFourth cubic-bezier(0.53,0.53,0.53,0.53) infinite;

				&.one{
					background-color:orange;
					margin: 200px 0 0 -5px;
				}

				&.two{
					background-color:blue;
					margin: 200px 0 0 390px;
				}

				&.three{
					background-color:yellow;
					margin: 390px 0 0 200px;
				}

				&.four{
					background-color:pink;
					margin: 5px 0 0 200px;
				}
			}
		}
	}
}