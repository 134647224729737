$pink-color:#ff0476;
$yellow-color: #ffc107;


.colors{



    &.pink{
      
      &.c-text{
        color:$pink-color;
      }

      &.c-background{
        background-color:$pink-color;
      }

      &.border{
        border-color:$pink-color;
      }
    }
    &.yellow{
      background-color:$yellow-color;

      &.border{
        border-color:$yellow-color;
      }
    }
  }