
.menu{

    height: 100px;
    transition: height .5s;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    max-width: 100%;
    justify-content: center;

    .show-mobile-menu-btn{
        font-size: 40px;
        display: none;

        &.hidden{
            display:none;
        }

        @media only screen and (max-width: 1280px) {
            display: inline-block;
            position: absolute; 
            top: 33px;
            right: 20px;

            &.times{
                top: 43px !important;
                right: 33px !important;
            }
        }
    }
    /*Menu: float version */
    &.float{
        position: fixed !important;
        z-index: 9999;
        background-color: #f5f7fa;
        max-width: 100vw;
        left: 0;
        width: 100%;
        height: 50px;
        transition: height .5s;
        top: 0;
        box-shadow: 0 0 30px 10px rgba(16, 22, 36, 0.1);
        padding: 0 50px;
        .logo{
            max-height: 45px;
            transition: all .5s;
            @media only screen and (max-width: 1280px) {
                max-height: 50px;
            }
        }

        @media only screen and (max-width: 1280px) {
            height: 100px;
        }

        
    }

    /*Logo: in header default menu*/
    .logo{
        max-width: 200px;
         /*Mobile version*/
         @media only screen and (max-width: 1280px) {
            margin: 20px 0 0 20px;
        }
    }

    /*Balls with menu in mobile version*/
    .mobile-links{
        /*Its not visible in desktop version*/
        width: 0;
        display: none;

        /*Mobile version*/
        @media only screen and (max-width: 1280px) {

            &.hidden{
                width: 0;
                display: none;
                transition: width .5s;
            }

            display: flex;
            flex-direction: column;
            position: fixed;
            list-style: none;
            padding-right: 10px;
            padding-top: 100px;
            top: 0px;
            right: 0px;
            margin-bottom: 10px;
            background-color:rgba(245, 247, 250,.95);
            height: 100vh;
            z-index: 1000;
            align-items: center;
            width: auto;
            padding-left: 0;
            box-shadow: 0 0 55px 10px rgba(0, 0, 0, 0.8);
            transition: width .5s;
            border: none;
            align-items: flex-end;

            li{
                border-radius: 100%;
                margin-bottom: 5px;
                padding: 0 10px;
                text-align: right;
                a{
                    color: rgba(0, 0, 0, 0.85) !important;
                    font-weight: bold;
                    &::after{
                        margin-top:35px;
                        right: 20px;
                        border:solid 1px #ff0476;
                        width: 80px;
                        content: " ";
                        position: absolute;
                        transition: width .1s;
                    }
                }
            }
        }
    }

    .links{

        list-style: none;
        padding: 0;
        margin-bottom: 0;
        margin-left: auto;
        display: flex;
        flex-direction: row;

        /*Mobile version*/
        @media only screen and (max-width: 1280px) {
            display: none !important;
        }

        
        
        li{
            margin-left:5px; 
            margin-right:5px;
            display: inline-flex;
            font-weight: 700;
            font-size:1.2em;
            font-family: "Nunito Sans";

            a{
                position: relative;
                padding: 1rem 0 0.5rem;
                margin: 0 .5rem;
                text-decoration: none;
                color:black;

                &::after {
                    content: '';
                    position: absolute;
                    left: 50%;
                    bottom: 0;
                    transform: translateX(-50%) scaleX(0);
                    transform-origin: 50% 50%;
                    width: 100%;
                    height: 4px;
                    border-radius: 3px;
                    background-color: #ff0476;
                    transition: transform 250ms;
                }
    
                &:hover {
                    &::after {
                        transform: translateX(-50%) scaleX(1);
                    }
                    
                }
            }
        }

        li:first-child{
            margin-right: 20px;
            margin-left: 0 !important;
        }

        li:last-child{
            margin-left: 20px;
            margin-right: 0 !important;
        }
    }
    
}