@keyframes pulse {
	25% {
		opacity: 0.4;
	}
	
	100% {
		transform: scale(1.5);
	}
}

.pulse-container{

    svg{
        margin-top:7px;
        overflow: visible;
    }

    
    .pulse {
        z-index: 99;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 130rem;
        

        width: 200px;
        height: 200px;
        border: solid 1px red;

        
        
        circle {
            fill:  #98d0f2;
            transform: scale(0);
            opacity: 0;
            transform-origin: 50% 50%;
            animation: pulse 2s cubic-bezier(.5,.5,0,1) ;
            animation-iteration-count: infinite;
            
            /*&:nth-child(2) {
                fill: #7fc6a4;
                animation: pulse 2s 0.75s cubic-bezier(.5,.5,0,1);
                animation-iteration-count: infinite;
            }
            
            &:nth-child(3) {
                fill: #e5f77d;
                animation: pulse 2s 1.5s cubic-bezier(.5,.5,0,1);
                animation-iteration-count: infinite;
            }*/
            
        }
        
    }
}


